<template>
  <b-overlay
    :show="show"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <b-icon-controller
          font-scale="3"
          animation="cylon"
        />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <div class="card">
      <div class="card-body">
        <h3 class="p-2">
          ยอดเล่นรายวัน
        </h3>
        <div>
          <h2 class="text-dark font-medium-3">
            <i class="fa fa-search" /> ค้นหา
          </h2>
        </div>
        <div class="row">
          <div class="col-md-5">
            <label>เดือนที่</label>
            <div
              class="form-group"
            >
              <div class="input-group mb-1">
                <span class="input-group-text">เดือนที่</span>
                <b-form-select
                  id="perPageSelect"
                  v-model="monthSelect"
                  inline
                  :options="month_arr"
                />
                <!-- <flat-pickr
                  v-model="dateStart"
                  :config="flatpickrOptions"
                  class="form-control"
                /> -->
                <span class="input-group-text">ปีที่</span>
                <b-form-select
                  id="perPageSelect"
                  v-model="yearSelect"
                  inline
                  :options="year_arr"
                />
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div
              class="form-group"
            >
              <label for="username">รหัสสมาชิก</label>
              <input
                v-model="search_userID"
                type="text"
                class="form-control"
                placeholder="รหัสสมาชิก"
              >
            </div>
          </div>
          <div class="col-md-12">
            <button
              class="btn btn-gradient-dark"
              @click="GetData()"
            >
              <feather-icon icon="SearchIcon" /> ค้นหา
            </button>
          </div>
        </div>

      </div>
    </div>
    <b-card
      no-body
    >
      <b-card-body>
        <div class="d-flex justify-content-between flex-wrap">
          <div
            style="display: flex"
          >
            <b-button
              variant="gradient-primary"
              class="mr-1"
              @click="exportToExcel()"
            >
              โหลดไฟล์ Excel
            </b-button>
            <!-- <b-button

              variant="gradient-danger"
              @click="exportToPdf"
            >
              โหลดไฟล์ PDF
            </b-button> -->
          </div>
        </div>
      </b-card-body>
      <b-table
        ref="table"
        small
        striped
        hover
        responsive
        show-empty
        class="position-relative items-center"
        :per-page="perPage"
        :items="items"
        :fields="fields"
      >
        <template #empty="scope">
          <div class="p-2 text-center">
            <img
              src="/box.png"
              alt="empty"
              height="40px"
            > {{ scope.emptyFiltered ? 'No matching records found' : 'ไม่พบข้อมูล' }}
          </div>
        </template>
        <template #cell(day1)="data">
          <b-button
            v-ripple.400="'rgba(30, 30, 30, 0.15)'"
            :disabled="!data.item.day1"
            variant="flat-dark"
            @click="ShowDetail(`${yearSelect}-${monthSelect}-01`,data.item.userID)"
          >
            {{ Commas(data.item.day1) }}
          </b-button>
        </template>
        <template #cell(day2)="data">
          <b-button
            v-ripple.400="'rgba(30, 30, 30, 0.15)'"
            :disabled="!data.item.day2"
            variant="flat-dark"
            @click="ShowDetail(`${yearSelect}-${monthSelect}-02`,data.item.userID)"
          >
            {{ Commas(data.item.day2) }}
          </b-button>
        </template>
        <template #cell(day3)="data">
          <b-button
            v-ripple.400="'rgba(30, 30, 30, 0.15)'"
            :disabled="!data.item.day3"
            variant="flat-dark"
            @click="ShowDetail(`${yearSelect}-${monthSelect}-03`,data.item.userID)"
          >
            {{ Commas(data.item.day3) }}
          </b-button>
        </template>
        <template #cell(day4)="data">
          <b-button
            v-ripple.400="'rgba(30, 30, 30, 0.15)'"
            :disabled="!data.item.day4"
            variant="flat-dark"
            @click="ShowDetail(`${yearSelect}-${monthSelect}-04`,data.item.userID)"
          >
            {{ Commas(data.item.day4) }}
          </b-button>
        </template>
        <template #cell(day5)="data">
          <b-button
            v-ripple.400="'rgba(30, 30, 30, 0.15)'"
            :disabled="!data.item.day5"
            variant="flat-dark"
            @click="ShowDetail(`${yearSelect}-${monthSelect}-05`,data.item.userID)"
          >
            {{ Commas(data.item.day5) }}
          </b-button>
        </template>
        <template #cell(day6)="data">
          <b-button
            v-ripple.400="'rgba(30, 30, 30, 0.15)'"
            :disabled="!data.item.day6"
            variant="flat-dark"
            @click="ShowDetail(`${yearSelect}-${monthSelect}-06`,data.item.userID)"
          >
            {{ Commas(data.item.day6) }}
          </b-button>
        </template>
        <template #cell(day7)="data">
          <b-button
            v-ripple.400="'rgba(30, 30, 30, 0.15)'"
            :disabled="!data.item.day7"
            variant="flat-dark"
            @click="ShowDetail(`${yearSelect}-${monthSelect}-07`,data.item.userID)"
          >
            {{ Commas(data.item.day7) }}
          </b-button>
        </template>
        <template #cell(day8)="data">
          <b-button
            v-ripple.400="'rgba(30, 30, 30, 0.15)'"
            :disabled="!data.item.day8"
            variant="flat-dark"
            @click="ShowDetail(`${yearSelect}-${monthSelect}-08`,data.item.userID)"
          >
            {{ Commas(data.item.day8) }}
          </b-button>
        </template>
        <template #cell(day9)="data">
          <b-button
            v-ripple.400="'rgba(30, 30, 30, 0.15)'"
            variant="flat-dark"
            :disabled="!data.item.day9"
            @click="ShowDetail(`${yearSelect}-${monthSelect}-09`,data.item.userID)"
          >
            {{ Commas(data.item.day9) }}
          </b-button>
        </template>
        <template #cell(day10)="data">
          <b-button
            v-ripple.400="'rgba(30, 30, 30, 0.15)'"
            variant="flat-dark"
            :disabled="!data.item.day10"
            @click="ShowDetail(`${yearSelect}-${monthSelect}-10`,data.item.userID)"
          >
            {{ Commas(data.item.day10) }}
          </b-button>
        </template>
        <template #cell(day11)="data">
          <b-button
            v-ripple.400="'rgba(30, 30, 30, 0.15)'"
            variant="flat-dark"
            :disabled="!data.item.day11"
            @click="ShowDetail(`${yearSelect}-${monthSelect}-11`,data.item.userID)"
          >
            {{ Commas(data.item.day11) }}
          </b-button>
        </template>
        <template #cell(day12)="data">
          <b-button
            v-ripple.400="'rgba(30, 30, 30, 0.15)'"
            variant="flat-dark"
            :disabled="!data.item.day12"
            @click="ShowDetail(`${yearSelect}-${monthSelect}-12`,data.item.userID)"
          >
            {{ Commas(data.item.day12) }}
          </b-button>
        </template>
        <template #cell(day13)="data">
          <b-button
            v-ripple.400="'rgba(30, 30, 30, 0.15)'"
            variant="flat-dark"
            :disabled="!data.item.day13"
            @click="ShowDetail(`${yearSelect}-${monthSelect}-13`,data.item.userID)"
          >
            {{ Commas(data.item.day13) }}
          </b-button>
        </template>
        <template #cell(day14)="data">
          <b-button
            v-ripple.400="'rgba(30, 30, 30, 0.15)'"
            variant="flat-dark"
            :disabled="!data.item.day14"
            @click="ShowDetail(`${yearSelect}-${monthSelect}-14`,data.item.userID)"
          >
            {{ Commas(data.item.day14) }}
          </b-button>
        </template>
        <template #cell(day15)="data">
          <b-button
            v-ripple.400="'rgba(30, 30, 30, 0.15)'"
            variant="flat-dark"
            :disabled="!data.item.day15"
            @click="ShowDetail(`${yearSelect}-${monthSelect}-15`,data.item.userID)"
          >
            {{ Commas(data.item.day15) }}
          </b-button>
        </template>
        <template #cell(day16)="data">
          <b-button
            v-ripple.400="'rgba(30, 30, 30, 0.15)'"
            variant="flat-dark"
            :disabled="!data.item.day16"
            @click="ShowDetail(`${yearSelect}-${monthSelect}-16`,data.item.userID)"
          >
            {{ Commas(data.item.day16) }}
          </b-button>
        </template>
        <template #cell(day17)="data">
          <b-button
            v-ripple.400="'rgba(30, 30, 30, 0.15)'"
            variant="flat-dark"
            :disabled="!data.item.day17"
            @click="ShowDetail(`${yearSelect}-${monthSelect}-17`,data.item.userID)"
          >
            {{ Commas(data.item.day17) }}
          </b-button>
        </template>
        <template #cell(day18)="data">
          <b-button
            v-ripple.400="'rgba(30, 30, 30, 0.15)'"
            variant="flat-dark"
            :disabled="!data.item.day18"
            @click="ShowDetail(`${yearSelect}-${monthSelect}-18`,data.item.userID)"
          >
            {{ Commas(data.item.day18) }}
          </b-button>
        </template>
        <template #cell(day19)="data">
          <b-button
            v-ripple.400="'rgba(30, 30, 30, 0.15)'"
            variant="flat-dark"
            :disabled="!data.item.day19"
            @click="ShowDetail(`${yearSelect}-${monthSelect}-19`,data.item.userID)"
          >
            {{ Commas(data.item.day19) }}
          </b-button>
        </template>
        <template #cell(day20)="data">
          <b-button
            v-ripple.400="'rgba(30, 30, 30, 0.15)'"
            variant="flat-dark"
            :disabled="!data.item.day20"
            @click="ShowDetail(`${yearSelect}-${monthSelect}-20`,data.item.userID)"
          >
            {{ Commas(data.item.day20) }}
          </b-button>
        </template>
        <template #cell(day22)="data">
          <b-button
            v-ripple.400="'rgba(30, 30, 30, 0.15)'"
            variant="flat-dark"
            :disabled="!data.item.day22"
            @click="ShowDetail(`${yearSelect}-${monthSelect}-22`,data.item.userID)"
          >
            {{ Commas(data.item.day22) }}
          </b-button>
        </template>
        <template #cell(day23)="data">
          <b-button
            v-ripple.400="'rgba(30, 30, 30, 0.15)'"
            variant="flat-dark"
            :disabled="!data.item.day23"
            @click="ShowDetail(`${yearSelect}-${monthSelect}-23`,data.item.userID)"
          >
            {{ Commas(data.item.day23) }}
          </b-button>
        </template>
        <template #cell(day24)="data">
          <b-button
            v-ripple.400="'rgba(30, 30, 30, 0.15)'"
            variant="flat-dark"
            :disabled="!data.item.day24"
            @click="ShowDetail(`${yearSelect}-${monthSelect}-24`,data.item.userID)"
          >
            {{ Commas(data.item.day24) }}
          </b-button>
        </template>
        <template #cell(day25)="data">
          <b-button
            v-ripple.400="'rgba(30, 30, 30, 0.15)'"
            variant="flat-dark"
            :disabled="!data.item.day25"
            @click="ShowDetail(`${yearSelect}-${monthSelect}-25`,data.item.userID)"
          >
            {{ Commas(data.item.day25) }}
          </b-button>
        </template>
        <template #cell(day26)="data">
          <b-button
            v-ripple.400="'rgba(30, 30, 30, 0.15)'"
            variant="flat-dark"
            :disabled="!data.item.day26"
            @click="ShowDetail(`${yearSelect}-${monthSelect}-26`,data.item.userID)"
          >
            {{ Commas(data.item.day26) }}
          </b-button>
        </template>
        <template #cell(day27)="data">
          <b-button
            v-ripple.400="'rgba(30, 30, 30, 0.15)'"
            variant="flat-dark"
            :disabled="!data.item.day27"
            @click="ShowDetail(`${yearSelect}-${monthSelect}-27`,data.item.userID)"
          >
            {{ Commas(data.item.day27) }}
          </b-button>
        </template>
        <template #cell(day28)="data">
          <b-button
            v-ripple.400="'rgba(30, 30, 30, 0.15)'"
            variant="flat-dark"
            :disabled="!data.item.day28"
            @click="ShowDetail(`${yearSelect}-${monthSelect}-28`,data.item.userID)"
          >
            {{ Commas(data.item.day28) }}
          </b-button>
        </template>
        <template #cell(day29)="data">
          <b-button
            v-ripple.400="'rgba(30, 30, 30, 0.15)'"
            variant="flat-dark"
            :disabled="!data.item.day29"
            @click="ShowDetail(`${yearSelect}-${monthSelect}-29`,data.item.userID)"
          >
            {{ Commas(data.item.day29) }}
          </b-button>
        </template>
        <template #cell(day30)="data">
          <b-button
            v-ripple.400="'rgba(30, 30, 30, 0.15)'"
            variant="flat-dark"
            :disabled="!data.item.day30"
            @click="ShowDetail(`${yearSelect}-${monthSelect}-30`,data.item.userID)"
          >
            {{ Commas(data.item.day30) }}
          </b-button>
        </template>
        <template #cell(day31)="data">
          <b-button
            v-ripple.400="'rgba(30, 30, 30, 0.15)'"
            variant="flat-dark"
            :disabled="!data.item.day31"
            @click="ShowDetail(`${yearSelect}-${monthSelect}-31`,data.item.userID)"
          >
            {{ Commas(data.item.day31) }}
          </b-button>
        </template>
        <template #cell(total)="data">
          <p class="text-primary">
            {{ Commas(SumData(data.item)) }}
          </p>
        </template>
      </b-table>
      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

        <!-- page length -->
        <b-form-group
          label="Per Page"
          label-cols="8"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
            @change="GetData()"
          />
        </b-form-group>

        <!-- pagination -->
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
            @input="GetData()"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </b-card>
    <b-modal
      ref="detail-modal"
      title="รายละเอียด"
      ok-only
      ok-title="ปิด"
      size="lg"
    >
      <div>
        <b-table
          ref="table"
          small
          striped
          hover
          responsive
          show-empty
          class="position-relative items-center"
          :per-page="DetailPerPage"
          :items="BetDetail"
          :fields="DetailFields"
        >
          <template #cell(index)="data">
            {{ DetailPage * (currentPage - 1) + (data.index + 1) }}
          </template>
          <template #cell(bet_amount)="data">
            <p class="text-success">
              {{ Commas(data.item.bet_amount) }}
            </p>
          </template>
          <template #cell(win_amount)="data">
            <p class="text-warning">
              {{ Commas(data.item.win_amount) }}
            </p>
          </template>
          <template #cell(cancel_amount)="data">
            <p class="text-danger">
              {{ Commas(data.item.cancel_amount) }}
            </p>
          </template>
          <template #cell(bet_count)="data">
            <p class="text-success">
              {{ Commas(data.item.bet_count) }}
            </p>
          </template>
          <template #cell(win_count)="data">
            <p class="text-warning">
              {{ Commas(data.item.win_count) }}
            </p>
          </template>
          <template #cell(cancel_count)="data">
            <p class="text-danger">
              {{ Commas(data.item.cancel_count) }}
            </p>
          </template>

          <template #cell(stamp_last)="data">
            {{ timeFormate(data.item.stamp_last) }}
          </template>

        </b-table>
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

          <!-- page length -->
          <b-form-group
            label="Per Page"
            label-cols="8"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
          >
            <b-form-select
              id="perPageSelect"
              v-model="DetailPerPage"
              size="sm"
              inline
              :options="pageOptions"
              @change="ShowDetail(DetailFind.findDate,DetailFind.username)"
            />
          </b-form-group>

          <!-- pagination -->
          <div>
            <b-pagination
              v-model="DetailPage"
              :total-rows="DetailTotalRows"
              :per-page="DetailPerPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
              @input="ShowDetail(DetailFind.findDate,DetailFind.username)"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </div>

    </b-modal>
  </b-overlay>
</template>

<script>
import moment from 'moment-timezone'
import {
  VBTooltip,
  BCard,
  BTable,
  BFormGroup,
  BFormSelect,
  BPagination,
  BButton,
  BCardBody,
  VBToggle,
  BOverlay,
  BIconController,
  BModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
// import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    // flatPickr,
    // BRow,
    // BCol,
    BCard,
    BTable,
    BFormGroup,
    BFormSelect,
    BPagination,
    BButton,
    BCardBody,
    BOverlay,
    BIconController,
    BModal,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },

  data() {
    return {
      perPage: 10,
      pageOptions: [10, 15, 20, 100, 500, 1000],
      totalRows: 1,
      currentPage: 1,
      DetailPage: 1,
      DetailTotalRows: 1,
      DetailPerPage: 10,
      BetDetail: [],
      DetailFields: [
        { key: 'game_provider', label: 'ประเภท' },
        { key: 'game_category', label: 'แคตตากอรี่' },
        { key: 'game_product_id', label: 'โปรดักซ์' },
        { key: 'game_name', label: 'ชื่อ' },
        { key: 'bet_amount', label: 'ยอดเดิมพัน' },
        { key: 'win_amount', label: 'ยอดรางวัล' },
        { key: 'cancel_amount', label: 'ยอดยกเลิก' },
        { key: 'bet_count', label: 'จำนวนเดิมพัน' },
        { key: 'win_count', label: 'จำนวนให้รางวัล' },
        { key: 'cancel_count', label: 'จำนวนยกเลิก' },
        { key: 'stamp_last', label: 'อัพเดตล่าสุด' },
      ],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      NumberItem: [
        { value: 1, text: 100 },
        { value: 7, text: 500 },
        { value: 2, text: 1000 },
        { value: 3, text: 1500 },
        { value: 4, text: 2000 },
      ],

      fields: [
        { key: 'userID', label: 'รหัสสมาชิก' },
        { key: 'day1', label: '1' },
        { key: 'day2', label: '2' },
        { key: 'day3', label: '3' },
        { key: 'day4', label: '4' },
        { key: 'day5', label: '5' },
        { key: 'day6', label: '6' },
        { key: 'day7', label: '7' },
        { key: 'day8', label: '8' },
        { key: 'day9', label: '9' },
        { key: 'day10', label: '10' },
        { key: 'day11', label: '11' },
        { key: 'day12', label: '12' },
        { key: 'day13', label: '13' },
        { key: 'day14', label: '14' },
        { key: 'day15', label: '15' },
        { key: 'day16', label: '16' },
        { key: 'day17', label: '17' },
        { key: 'day18', label: '18' },
        { key: 'day19', label: '19' },
        { key: 'day20', label: '20' },
        { key: 'day22', label: '22' },
        { key: 'day23', label: '23' },
        { key: 'day24', label: '24' },
        { key: 'day25', label: '25' },
        { key: 'day26', label: '26' },
        { key: 'day27', label: '27' },
        { key: 'day28', label: '28' },
        { key: 'day29', label: '29' },
        { key: 'day30', label: '30' },
        { key: 'day31', label: '31' },
        { key: 'total', label: 'รวม' },
      ],

      items: [
        // {
        //   userID: '',
        //   day1: 0,
        //   day2: 0,
        //   day3: 0,
        //   day4: 0,
        //   day5: 0,
        //   day6: 0,
        //   day7: 0,
        //   day8: 0,
        //   day9: 0,
        //   day10: 0,
        //   day11: 0,
        //   day12: 0,
        //   day13: 0,
        //   day14: 0,
        //   day15: 0,
        //   day16: 0,
        //   day17: 0,
        //   day18: 0,
        //   day19: 0,
        //   day20: 0,
        //   day22: 0,
        //   day23: 0,
        //   day24: 0,
        //   day25: 0,
        //   day26: 0,
        //   day27: 0,
        //   day28: 0,
        //   day29: 0,
        //   day30: 0,
        //   day31: 0,
        // },
      ],
      show: false,
      dateStart: new Date(),
      dateEnd: new Date(),
      monthSelect: moment().format('MM'),
      yearSelect: moment().format('YYYY'),
      search_userID: null,
      flatpickrOptions: {
        mode: 'single',
        dateFormat: 'Y-m-d',
        monthSelectorType: 'static',
        minDate: '1900-01',
        maxDate: '2100-12',
      },
      month_arr: [
        { text: 'มกราคม', value: '01' },
        { text: 'กุมภาพันธ์', value: '02' },
        { text: 'มีนาคม', value: '03' },
        { text: 'เมษายน', value: '04' },
        { text: 'พฤษภาคม', value: '05' },
        { text: 'มิถุนายน', value: '06' },
        { text: 'กรกฎาคม', value: '07' },
        { text: 'สิงหาคม', value: '08' },
        { text: 'กันยายน', value: '09' },
        { text: 'ตุลาคม', value: '10' },
        { text: 'พฤศจิกายน', value: '11' },
        { text: 'ธันวาคม', value: '12' },
      ],
      year_arr: [
        { text: '2566', value: '2023' },
        { text: '2567', value: '2024' },
        { text: '2568', value: '2025' },
        { text: '2569', value: '2026' },
      ],
      DetailFind: {
        findDate: '',
        username: '',
      },
    }
  },
  mounted() {
    this.GetData()
  },
  methods: {
    ShowDetail(findDate, userID) {
      this.show = true
      this.BetDetail = []
      this.$refs['detail-modal'].show()
      const params = {
        findDate,
        username: userID,
      }
      this.DetailFind = params
      this.$http.get('/admin/GetBetDetail', { params })
        .then(response => {
          this.BetDetail = response.data
          // console.log(response.data)
          this.show = false
        }).catch(err => {
          console.log(err)
          this.show = false
        })
    },
    timeFormate(data) {
      return moment(data).tz('Asia/Bangkok').format('DD/MM/YYYY HH:mm:ss')
    },
    time(data) {
      return moment(data).tz('Asia/Bangkok').format('DD/MM/YYYY')
    },
    GetData() {
      this.show = true
      const params = {
        dateStart: `${this.yearSelect}-${this.monthSelect}-01`,
        // dateEnd: this.dateEnd,
        search_userID: this.search_userID,
        perPage: this.perPage,
        page: this.currentPage,
      }
      this.$http.get('/admin/DailyPlayTotal', { params })
        .then(response => {
          this.items = response.data.data.map(ele => {
            const obj = {
              userID: ele.userID,
              ...ele.data,
            }
            return obj
          })
          this.totalRows = response.data.total
          this.show = false
        }).catch(err => {
          console.log(err)
          this.show = false
        })
    },
    exportToExcel() {
      this.show = true
      const params = {
        dateStart: `${this.yearSelect}-${this.monthSelect}-01`,
        // dateEnd: this.dateEnd,
        search_userID: this.search_userID,
        perPage: this.perPage,
        page: this.currentPage,
      }
      this.$http.get('/admin/export/DailyPlay', { params })
        .then(response => {
        // console.log(response.data)
          const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = 'ยอดเล่นรายวัน.xls' // ระบุชื่อไฟล์ที่ต้องการบันทึก
          link.click()
          this.show = false
        }).catch(err => {
          console.log(err)
          this.show = false
        })
    },
    SumData(data) {
      let sum = 0
      const Lgdata = { ...data }
      delete Lgdata.userID
      // eslint-disable-next-line no-restricted-syntax
      for (const key in Lgdata) {
        if (Object.prototype.hasOwnProperty.call(Lgdata, key)) {
          const element = Lgdata[key]
          sum += element
        }
      }
      return sum
    },
    Commas(x) {
      if (x === undefined) {
        return '-'
      }
      if (Math.round(x) !== x && x > 0) {
        const Num = Number(x)
        const y = Num.toFixed(2)
        return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.input-group-text {
    border-radius: 0;
}
</style>
